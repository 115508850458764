import { Injector, Component, OnInit, Inject } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { AppConsts } from '@shared/AppConsts';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import { PermissionCheckerService } from 'abp-ng2-module';
@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()]
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {

    isdevice;
    isMobile;
    isTablet;
    isDesktopDevice;
    IsNotVisibleMenu;
    deviceInfo:any;

    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle'
    };

    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active'
    };

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
  
    constructor(
    
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        _dateTimeService: DateTimeService,
        private deviceService: DeviceDetectorService,
        private _permissionCheckerService: PermissionCheckerService
    ) {                
        super(injector, _dateTimeService);
        this.deviceInfo = null;
        this.IsNotVisibleMenu=this._permissionCheckerService.isGranted("Layout.Menu.Visible");
    }

    dispositive():void{
        this.dispositive();
        this.deviceInfo = this.deviceService.getDeviceInfo();
         this.isMobile = this.deviceService.isMobile();
         this.isTablet =this.deviceService.isTablet() ;
         this.isDesktopDevice = this.deviceService.isDesktop();
        this.isdevice =this.deviceService.isTablet()?"Tablet":this.deviceService.isDesktop()?"Desktop":this.deviceService.isMobile()?"Mobil":abp.message.error("Dispositivo no reconocido");
        console.log(this.deviceInfo);
        console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        console.log(this.isTablet);  // returns if the device us a tablet (iPad etc)
        console.log(this.isDesktopDevice); // returns if the app is running on a Desktop browser.
    
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/upm-logos/UPM_New-Biofore-Logo_RGB.png';
    //defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/upm-logos/UPM_New-Biofore-Logo_WHITE.png';
}
