<div [ngClass]="{'subscription-bar-visible': subscriptionStatusBarVisible()}" class="d-flex flex-column flex-root">
    <default-layout *ngIf="theme=='default'"></default-layout>
    <theme5-layout *ngIf="theme=='theme5'"></theme5-layout>

</div>

<kt-scroll-top></kt-scroll-top>

<linkedAccountsModal *ngIf="!installationMode" #linkedAccountsModal (modalClose)="getRecentlyLinkedUsers()"></linkedAccountsModal>
<userDelegationsModal #userDelegationsModal></userDelegationsModal>
<changePasswordModal #changePasswordModal></changePasswordModal>
<changeProfilePictureModal #changeProfilePictureModal></changeProfilePictureModal>
<mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
<notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>

<commonLookupModal #userLookupModal (itemSelected)="chatBarComponent.addFriendSelected($event)"></commonLookupModal>
<chat-bar #chatBarComponent *ngIf="!installationMode" [userLookupModal]="userLookupModal"></chat-bar>
<theme-selection-panel #themeSelectionPanelComponent *ngIf="!installationMode && isQuickThemeSelectEnabled"></theme-selection-panel> 
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>